import React from "react";
import {Link} from "gatsby";
import styled from 'styled-components';
import {capitalize} from "../utils/misc";
import RecipeThumb from "./RecipeThumb";
import {
  CARD_BG_COLOR,
  TOPIC_THUMB_HOVER_COLOR
} from "../styles/colors";
import Section from "./Section";
import {responsive} from "../styles/dimens";

const TopicStubComponent = React.memo(
  ({topic: {title, slug, recipes}, className}) => (
    <Section className={className}>
      <Link to={`/recipes/${slug}`}>
        <h3 className='title'>{capitalize(title)}</h3>
        <p className='description'>Comparison of {recipes.length} recipes</p>
        {recipes.map((recipe, i) => (
          <RecipeThumb key={i} recipe={recipe} className='thumb' />
        ))}
      </Link>
    </Section>
));

const TopicStub = React.memo(styled(TopicStubComponent)`
  display: inline-block;
  text-align: center;
  color: inherit;
  margin: 10px;
  background-color: ${CARD_BG_COLOR};

  a {
    width: 296px;
    padding: 26px 0;
    display: inline-block;
  }

  a:hover {
    text-decoration: none;
    ${responsive({
      'phablet-desktop': `
        background-color: ${TOPIC_THUMB_HOVER_COLOR};
      `
    })};
  }

  a, a:visited, a:active {
    color: inherit;
  }
  
  .title {
    margin: 8px 0 14px 0;
  }
  
  .description {
    margin: 0 0 15px 0;
  }
  
  .thumb:not(:first-of-type) {
    margin-left: 5px;
  }
  
  .thumb:not(:last-of-type) {
    margin-right: 5px;
  }
  
  img {
    -webkit-filter: grayscale(30%);
    filter: grayscale(30%);
    pointer-events: none;
  }
`);

export default TopicStub;
