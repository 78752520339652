import React from "react";
import Media from 'react-media';
import * as PropTypes from "prop-types";
import { Match } from "@reach/router";
import { BREAKPOINTS } from "../styles/dimens";

export const SmallScreenOnly = React.memo(({children}) => (
  <Media query={`(max-width: ${BREAKPOINTS.phablet - 1}px)`}>
    {matches => matches ? children : null}
  </Media>
));

export const NotSmallScreenOnly = React.memo(({children}) => (
  <Media query={`(min-width: ${BREAKPOINTS.phablet}px)`}>
    {matches => matches ? children : null}
  </Media>
));

// export const LargeScreenMedia = ({ children }) => (
//   <Media query={`(min-width: ${BREAKPOINTS.desktop}px`} children={children} />
// );
//
// export const SmallScreen = React.memo(({ children }) => (
//   <div className='mobile'>{children}</div>
// ));
//
// export const NotSmallScreen = React.memo(({ children }) => (
//   <div className='phablet-desktop'>{children}</div>
// ));
//
// export const LargeScreen = React.memo(({ children }) => (
//   <div className='desktop'>{children}</div>
// ));
//
// export const NotLargeScreen = React.memo(({ children }) => (
//   <div className='mobile-tablet'>{children}</div>
// ));

const matches = (match) => match && match.path === match.uri;

export const LargeScreenOrMatch = React.memo(({path, forceShow=false,
                                                forceHide=false, children}) => {
  if (forceHide) {
    return null;
  }
  else {
    if (!forceShow) {
      return (
        <>
          <SmallScreenOnly>
            <Match path={`/${path}`}>
              {props => {
                return (
                  <div style={{
                    display: matches(props.match) ? 'inherit' : 'none'
                  }}>
                    {children}
                  </div>
                );
              }}
            </Match>
          </SmallScreenOnly>
          <NotSmallScreenOnly>
            {children}
          </NotSmallScreenOnly>
        </>
      )
    } else {
      return children
    }
  }
});

LargeScreenOrMatch.propTypes = {
  path: PropTypes.string.isRequired,
  forceShow: PropTypes.bool
};
