import React from "react";
import styled from 'styled-components';
import {THUMB_SIZE} from "../styles/dimens";

const Container = React.memo(styled.div`
  display: inline-block;
  max-width: ${THUMB_SIZE}px;
  height: ${THUMB_SIZE}px;
  border: thin solid #E3E3E3;
  border-radius: 50%;
  overflow: hidden;
  
  img {
    width: ${Math.floor(THUMB_SIZE * 1.5)}px;
    height: ${THUMB_SIZE}px;
    margin-left: -${Math.floor(THUMB_SIZE * 0.25)}px;
  }
`);

const RecipeThumb = React.memo(({recipe, className}) => (
  <Container className={className}>
    <img src={`https://i.ytimg.com/vi/${recipe.videoId}/mqdefault.jpg`}
         alt={recipe.title}
         width={THUMB_SIZE}
         height={THUMB_SIZE}
    />
  </Container>
));

export default RecipeThumb;
