import TopicStub from "./TopicStub";
import React from "react";
import styled from "styled-components";

const ArticlesListComponent = ({articles, className}) => (
  <div className={className}>
    {articles.map(topic => <TopicStub key={topic.title} topic={topic} />)}
  </div>
);

const ArticlesList = React.memo(styled(ArticlesListComponent)`
  text-align: center;
`);

export default ArticlesList;