import React from "react";
import styled from 'styled-components';
import {
  MAX_MAIN_WIDTH,
  PAGE_PADDING,
  PAGE_PADDING_INT,
  responsive
} from "../styles/dimens"
import {AMAZON_AFFILIATE_ID, DOMAIN} from "../constants"
import {NotSmallScreenOnly} from "../components/MediaQueries"
import ArticlesList from "../components/ArticlesList";
import Helmet from "react-helmet";
import htmlToText from "html-to-text";

const Container = React.memo(styled.main`
  max-width: 740px;
  margin: 0 auto;
  h1 {
    padding: ${PAGE_PADDING} ${PAGE_PADDING} 0 ${PAGE_PADDING_INT - 1}px;
    margin-bottom: 15px;
    ${responsive({
      'phablet-desktop': 'margin-top: 10px;',
      mobile: 'margin-top: 5px'
    })};
  }
  .body {
    display: flex;
    .main {
      flex-grow: 1;
    }
  }
  article {
      padding: 0 ${PAGE_PADDING};
      flex-grow: 1;
      box-sizing: border-box;
      ${responsive({
        'phablet-desktop': `padding-right: 50px;`
      })};
      max-width: ${MAX_MAIN_WIDTH};
  }
  .references {
    padding: 0 ${PAGE_PADDING};
    margin-top: 20px;
    li {
        padding-bottom: 0.75em;
        line-height: 1.33em;
    }
  }
  
  aside {
    margin-right: ${PAGE_PADDING};
    width: 120px;
    img {
      width: 120px;
    }
  }
`);

const SideBar = React.memo(({title, productIds}) => (
  <aside>
    {productIds.map(id => (
      <a key={id} target="_blank" rel='nofollow noopener' href={`https://www.amazon.com/gp/product/${id}/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=${id}&linkCode=as2&tag=${AMAZON_AFFILIATE_ID}`}>
        <img border="0" alt={`${title} books on Amazon`} src={`//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=${id}&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=${AMAZON_AFFILIATE_ID}`} />
      </a>
    ))}
  </aside>
));

const SEO = ({title, text, topics, keywords}) => {
  const jsonLd = {
    "@context": "http://schema.org/",
    "@graph": [
      {
        "@type": "Diet",
        "name": title,
        "headline": title,
        "overview": text,
        "description": text,
        "keywords": keywords,
        "subjectOf": topics.map(t => ({
          "@type": "Collection",
          "@id": `${DOMAIN}/recipes/${t.slug}`
        }))
      }
    ]
  };
  return (
    <script type="application/ld+json">
      {JSON.stringify(jsonLd)}
    </script>
  );
};

const References = ({references}) => (
  <div className='references'>
    <h3>References</h3>
    <ul>
      {references.map((reference, i) => (
        <li key={i}>
          <a target='_blank' rel='nofollow noopener'
             href={reference.url}>{reference.title}</a>
        </li>
      ))}
    </ul>
  </div>
)

const CategoryPage = React.memo(({pageContext, data}) => {
  const {title, html, topics, references, productIds, slug} = pageContext;
  const text = htmlToText.fromString(html,
  {wordwrap: false, ignoreHref: true});
  const keywords = `${title.toLowerCase()}, diet, recipes`;
  return (
    <Container>
      <Helmet>
        <title>{title}</title>
        <meta name='keywords' content={keywords} />
        <meta name='description' content={text} />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content='Recipe Watch' />
        <meta property="og:description" content={text} />
        <link rel="canonical" href={`${DOMAIN}/categories/${slug}`} />
      </Helmet>
      <SEO {...pageContext} text={text} keywords={keywords} />
      <h1>{title}</h1>
      <div className='body'>
        <div className='main'>
          <article>
            <summary dangerouslySetInnerHTML={{__html: html}} />
          </article>
          <ArticlesList articles={topics} />
          {references.length > 0 ?
            <References references={references} /> : null}
        </div>
        <NotSmallScreenOnly>
          <SideBar title={title} productIds={productIds} />
        </NotSmallScreenOnly>
      </div>
    </Container>
  )
});

export default CategoryPage;
