import React from "react";
import styled from "styled-components";
import {BORDER_RADIUS, responsive} from "../styles/dimens";
import { BORDER_COLOR, CARD_BG_COLOR} from "../styles/colors";
import {
  NotSmallScreenOnly,
  SmallScreenOnly
} from "./MediaQueries";

const SectionComponent = React.memo(({children, className}) => (
  <div className={className}>{children}</div>
));

const Section = React.memo(styled(SectionComponent)`
  box-shadow: black 0 0 4px 0;
  display: inline-block;
  ${responsive({
    mobile: `
      .content {
          padding: 10px 0;
      }
    `,
    'phablet-desktop': `
        background-color: ${CARD_BG_COLOR};
        // border: thin solid ${BORDER_COLOR};
        border-radius: ${BORDER_RADIUS}px;
    `
  })};
`);

export const SectionUnlessSmall = ({children, className}) => (
  <>
    <SmallScreenOnly>
      {children}
    </SmallScreenOnly>
    <NotSmallScreenOnly>
      <Section className={className}>
        {children}
      </Section>
    </NotSmallScreenOnly>
  </>
);

export default Section;